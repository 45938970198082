import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { required } from '^/common/validation';
import ContactsList from '^/contacts/list';
import { ContactResponse, ContactType } from '^/contacts/types';
import RenderDropdown from '^/form-helpers/render-dropdown';
import RenderInputField from '^/form-helpers/render-input-field';
import RenderToggleSwitch from '^/form-helpers/render-toggle-switch';
import { closeModal, openModal } from '^/modals/actions';
import RelationshipsAddModal from '^/relationships/add-modal';
import { RelationshipType } from '^/relationships/types';
import { StoreState } from '^/types';
import { User, UserRole } from './types';

export interface OwnProps {
  /* Actions used in this form - affects errors and loading states */
  actions: ReadonlyArray<AsyncActionSet>;
  contactsModified?: boolean;
  contacts?: ReadonlyArray<ContactResponse>;
  addContact?: (contact: ContactResponse) => void;
  removeContact?: (contact: ContactResponse) => void;
  isAdminView?: boolean;
  /* If present a cancel button will be shown that will fire this prop. */
  onCancel?(): void;
}

export type UserFormProps = InjectedFormProps<User, OwnProps> &
  OwnProps &
  ConnectedProps<typeof connector>;

export type UserRoleDropDownOptions = Array<{
  label: UserRole;
  value: UserRole;
}>;

const AdminLevelRolesDropDownOptions: UserRoleDropDownOptions = [
  {
    label: UserRole.SalesLevel,
    value: UserRole.SalesLevel,
  },
  {
    label: UserRole.OfficeLevel,
    value: UserRole.OfficeLevel,
  },
  {
    label: UserRole.FinanceLevel,
    value: UserRole.FinanceLevel,
  },
  {
    label: UserRole.AdminLevel,
    value: UserRole.AdminLevel,
  },
];

export const PracticeLevelRolesDropDownOptions: UserRoleDropDownOptions = [
  {
    label: UserRole.PracticeAdminLevel,
    value: UserRole.PracticeAdminLevel,
  },
  {
    label: UserRole.PracticeUserLevel,
    value: UserRole.PracticeUserLevel,
  },
  {
    label: UserRole.PracticePatientLevel,
    value: UserRole.PracticePatientLevel,
  },
];

class UserForm extends React.PureComponent<UserFormProps> {
  public render() {
    const {
      handleSubmit,
      submitting,
      actions,
      error,
      pristine,
      initialValues,
      roleValue,
      addContact,
      removeContact,
      contacts,
      contactsModified,
      isAdminView,
    } = this.props;

    const isPracticeAdminLevel = roleValue === UserRole.PracticeAdminLevel;
    const isPracticeUserLevel = roleValue === UserRole.PracticeUserLevel;
    const isPracticePatientLevel = roleValue === UserRole.PracticePatientLevel;

    const selectableRoles = isAdminView
      ? AdminLevelRolesDropDownOptions
      : PracticeLevelRolesDropDownOptions;

    const createFormView =
      actions.length > 0 ? actions[0].REQUEST.includes('CREATE') : false;

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Field
            label="First Name"
            name="first_name"
            component={RenderInputField}
            validate={required}
          />
          <Field
            label="Surname"
            name="last_name"
            component={RenderInputField}
            validate={required}
          />
        </FormGroup>
        <FormGroup>
          <Field
            label="Email"
            name="email"
            type="email"
            component={RenderInputField}
          />
          <Field label="Phone" name="phone" component={RenderInputField} />
        </FormGroup>
        <FormGroup>
          {!createFormView && (
            <Field
              label="User Status"
              name="status"
              component={RenderToggleSwitch}
              options={[
                {
                  label: 'Active',
                  value: 'Active',
                },
                { label: 'Locked', value: 'Locked' },
                { label: 'Archived', value: 'Archived' },
              ]}
            />
          )}
          <Field
            label="Role"
            name="role"
            component={RenderDropdown}
            options={selectableRoles}
          />
        </FormGroup>
        <ErrorRenderer
          error={[error]}
          actions={actions}
          fields={['non_field_errors']}
        />
        {addContact && !isAdminView && (
          <div className="form-buttons">
            <AppButton
              primary
              small
              disabled={
                submitting ||
                !(
                  isPracticeAdminLevel ||
                  isPracticeUserLevel ||
                  isPracticePatientLevel
                )
              }
              onClick={this.showModal}
            >
              Add Practice
            </AppButton>
          </div>
        )}
        <div className="form-buttons">
          {this.props.onCancel && (
            <AppButton type="button" onClick={this.props.onCancel}>
              Cancel
            </AppButton>
          )}
          <AppButton
            type="submit"
            primary
            loading={submitting}
            disabled={pristine && !contactsModified}
          >
            {initialValues.id ? 'Save Changes' : 'Create User'}
          </AppButton>
        </div>
        {(isPracticeAdminLevel ||
          isPracticeUserLevel ||
          isPracticePatientLevel) &&
          !!contacts?.length &&
          removeContact && (
            <ContactsList
              contacts={contacts}
              hideDob
              onRemove={removeContact}
            />
          )}
      </Form>
    );
  }

  public showModal = () =>
    this.props.openModal(
      <RelationshipsAddModal
        types={[RelationshipType.CompanyContact]}
        filters={{ type: ContactType.Practice }}
        contactType={ContactType.Practice}
        label="Practice"
        hideDob
        onCloseModal={this.props.closeModal}
        onContactSelected={this.addContactThenClose}
      />
    );

  public addContactThenClose = (contact: ContactResponse) => {
    const { addContact } = this.props;
    if (addContact) {
      addContact(contact);
    }
    this.props.closeModal();
  };
}

// Disconnected version used for testing
export { UserForm as TestableUserForm };

export const mapStateToProps = (state: StoreState) => ({
  roleValue: formValueSelector('userForm')(state, 'role'),
});

const connector = connect(mapStateToProps, { openModal, closeModal });

const form = reduxForm<User, OwnProps>({
  form: 'userForm',
  destroyOnUnmount: true,
});

// export default connector(form(UserForm));
export default form(connector(UserForm));
