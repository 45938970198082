import { TableCell, TableHeader } from '@dabapps/roe';
import { push } from 'connected-react-router';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { getPrimaryPostcode } from '^/address/helpers';
import {
  formatDate,
  getContactName,
  getContactSurname,
} from '^/common/helper-functions';
import ItemRow from '^/common/paginated-table/item-row';
import { PersonProfile } from '^/contacts/persons/types';
import { ContactResponse, getContactCategoryName } from '^/contacts/types';

const connector = connect(undefined, { push });

export interface Props extends ConnectedProps<typeof connector> {
  contactResponse: ContactResponse;
  showCategory?: boolean;
  showClinician?: boolean;
  url?: string;
  onSelect?: (contact: ContactResponse) => void;
}

export const PersonRow: React.SFC<{
  contact: ContactResponse;
  person: PersonProfile;
  showCategory?: boolean;
  showClinician?: boolean;
  onClick(contact: ContactResponse): void;
}> = ({ contact, person, showCategory = true, showClinician, onClick }) => (
  <ItemRow item={contact} onClick={onClick}>
    <TableCell>{person.first_name}</TableCell>
    <TableHeader>{getContactSurname(contact)}</TableHeader>
    {showCategory && <TableCell>{getContactCategoryName(contact)}</TableCell>}
    <TableCell>{contact.crm_id}</TableCell>
    <TableCell>{formatDate(person.dob)}</TableCell>
    <TableCell>{getPrimaryPostcode(contact)}</TableCell>
    <TableCell>{contact.status}</TableCell>
    {showClinician && <TableCell>{contact.clinician}</TableCell>}
  </ItemRow>
);

export const CompanyRow: React.SFC<{
  contact: ContactResponse;
  onClick(contact: ContactResponse): void;
}> = ({ contact, onClick }) => (
  <ItemRow item={contact} onClick={onClick}>
    <TableHeader colSpan={2}>{getContactName(contact)}</TableHeader>
    <TableCell>{getContactCategoryName(contact)}</TableCell>
    <TableCell>{contact.crm_id}</TableCell>
    <TableCell>-</TableCell>
    <TableCell>{getPrimaryPostcode(contact)}</TableCell>
    <TableCell>{contact.clinician}</TableCell>
  </ItemRow>
);

class ContactRow extends React.PureComponent<Props> {
  public render() {
    const { contactResponse, showCategory = true, showClinician } = this.props;

    if (contactResponse.person) {
      return (
        <PersonRow
          showCategory={showCategory}
          showClinician={showClinician}
          contact={contactResponse}
          person={contactResponse.person}
          onClick={this.navigateToContact}
        />
      );
    }

    return (
      <CompanyRow contact={contactResponse} onClick={this.navigateToContact} />
    );
  }

  public navigateToContact = (contact: ContactResponse) => {
    if (this.props.onSelect) {
      this.props.onSelect(contact);
      return;
    }
    if (this.props.url) {
      this.props.push(this.props.url);
    } else {
      this.props.push(`/contacts/${contact.id}`);
    }
  };
}

export { ContactRow as TestableContactRow };

export default connector(ContactRow);
