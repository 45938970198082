import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import { TableBody, TableHead, TableHeader, TableRow } from '@dabapps/roe';
import React, { ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { toggleSortFor } from '^/common/helper-functions';
import PaginatedTable from '^/common/paginated-table';
import { clearContacts, GET_CONTACTS, getContacts } from '^/contacts/actions';
import ContactRow from '^/contacts/row';
import { FilterList } from '^/filters/types';
import TableHeaderSort from '^/sorts/table-header-sort';
import { SortList } from '^/sorts/types';
import { StoreState } from '^/types';
import { getCurrentPage } from '^/utils/pagination-helpers';
import { ContactResponse } from './types';

interface OwnProps {
  title?: string;
  actions?: ReactNode | ReactNode[];
  url?: string;
  showClinician?: boolean;
  showCategory?: boolean;
  onSelect?: (contact: ContactResponse) => void;
}

interface StateProps {
  appliedSorts: SortList;
}

export type ContactsTableProps = OwnProps &
  ConnectedProps<typeof connector> &
  SetPropsInterface<StateProps>;

class ContactsTable extends React.PureComponent<ContactsTableProps> {
  public componentDidUpdate(prevProps: ContactsTableProps) {
    const { contacts, appliedSorts } = this.props;

    if (appliedSorts !== prevProps.appliedSorts) {
      this.props.getContacts(
        contacts ? contacts.filters : undefined,
        appliedSorts,
        undefined,
        undefined,
        this.props.userRole
      );
    }
  }

  public render() {
    const {
      contacts,
      loading,
      appliedSorts,
      setProps,
      title,
      showCategory = false,
      showClinician,
      actions,
      url,
      onSelect,
    } = this.props;

    return (
      <PaginatedTable
        actions={actions}
        changePage={this.changePage}
        paginatedData={contacts}
        loading={loading}
        primary
        title={title || 'Search results'}
      >
        <TableHead>
          <TableRow>
            <TableHeaderSort
              sortOrder={appliedSorts.person__first_name}
              onClick={toggleSortFor.bind(
                this,
                'person__first_name',
                appliedSorts,
                setProps
              )}
            >
              First Name
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.combined_name}
              onClick={toggleSortFor.bind(
                this,
                'combined_name',
                appliedSorts,
                setProps
              )}
            >
              Surname
            </TableHeaderSort>
            {showCategory && (
              <TableHeaderSort
                sortOrder={appliedSorts.type}
                onClick={toggleSortFor.bind(
                  this,
                  'type',
                  appliedSorts,
                  setProps
                )}
              >
                Category
              </TableHeaderSort>
            )}
            <TableHeaderSort
              sortOrder={appliedSorts.combined_name}
              onClick={toggleSortFor.bind(
                this,
                'crm_id',
                appliedSorts,
                setProps
              )}
            >
              Contact ID
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.combined_name}
              onClick={toggleSortFor.bind(
                this,
                'person__dob',
                appliedSorts,
                setProps
              )}
            >
              DOB
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.combined_name}
              onClick={toggleSortFor.bind(
                this,
                'address__postcode',
                appliedSorts,
                setProps
              )}
            >
              Postcode
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.combined_name}
              onClick={toggleSortFor.bind(
                this,
                'status',
                appliedSorts,
                setProps
              )}
            >
              Status
            </TableHeaderSort>
            {showClinician && <TableHeader>Clinician</TableHeader>}
          </TableRow>
        </TableHead>
        <TableBody>
          {getCurrentPage(contacts).map(contact => (
            <ContactRow
              onSelect={onSelect}
              url={url && `${url}${contact.id}`}
              key={contact.id}
              contactResponse={contact}
              showCategory={showCategory}
              showClinician={showClinician}
            />
          ))}
        </TableBody>
      </PaginatedTable>
    );
  }

  public changePage = (page: number, pageSize?: number, filters?: FilterList) =>
    this.props.getContacts(
      filters,
      this.props.appliedSorts,
      page,
      pageSize,
      this.props.userRole
    );
}

export { ContactsTable as TestableContactsTable };

export const getInitialProps = () => ({
  appliedSorts: {},
});

export const mapState = (state: StoreState) => ({
  userRole: state.loggedInUser!.role,
  contacts: state.contacts,
  loading: anyPending(state.responses, [GET_CONTACTS]),
});

const connector = connect(mapState, { getContacts, clearContacts });

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(ContactsTable)
);
