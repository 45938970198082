import { Modal, ModalBody, ModalHeader } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import AppButton from './app-button';
import AppButtonGroup from './app-button-group';

// Props that come from the parent component.
interface OwnProps {
  heading?: string;
  message: string | React.ReactNode;
  actionLabel?: string;
  action?: (...args: unknown[]) => void;
  closeModal: () => void;
}

export type ConfirmationModalProps = OwnProps;

class ConfirmationModal extends React.PureComponent<ConfirmationModalProps> {
  public render() {
    const {
      message,
      closeModal,
      actionLabel = 'Confirm',
      heading = 'Are you sure?',
      action,
    } = this.props;
    return (
      <Modal onClickOutside={closeModal}>
        <ModalHeader>
          <h2>{heading}</h2>
          <AppButton transparent onClick={closeModal}>
            <FontAwesomeIcon icon="times" />
          </AppButton>
        </ModalHeader>
        <ModalBody>
          <div className="modal-body-section">{message}</div>
          <div className="modal-body-section form-buttons">
            <AppButtonGroup>
              <AppButton onClick={closeModal}>
                {action ? 'Cancel' : 'Close'}
              </AppButton>
              {action && (
                <AppButton primary onClick={this.handleAction}>
                  {actionLabel}
                </AppButton>
              )}
            </AppButtonGroup>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  public handleAction = () => {
    if (this.props.action) {
      this.props.action();
    }

    this.props.closeModal();
  };
}

// Disconnected version used for testing
export { ConfirmationModal as TestableConfirmationModal };

export default ConfirmationModal;
