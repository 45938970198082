import moment from 'moment';
import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { WrappedFieldProps } from 'redux-form';

import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_PICKER_FORMAT,
} from '^/common/helper-functions';
import RenderFormField, { RenderFormFieldProps } from './render-form-field';

interface OwnProps {
  placeholder?: string;
  clearable?: boolean;
  showMonthYearPicker?: boolean;
  pickerProps?: Partial<ReactDatePickerProps>;
}

export type RenderDateFieldProps = OwnProps &
  RenderFormFieldProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

export class RenderDateField extends React.PureComponent<RenderDateFieldProps> {
  public render() {
    const {
      input,
      placeholder = DEFAULT_DATE_FORMAT,
      disabled,
      showMonthYearPicker,
      clearable = true,
      pickerProps = {},
    } = this.props;

    return (
      <RenderFormField {...this.props}>
        <ReactDatePicker
          dateFormat={DEFAULT_DATE_PICKER_FORMAT}
          selected={
            input.value ? moment(input.value, 'YYYY-MM-DD').toDate() : null
          }
          showMonthYearPicker={showMonthYearPicker}
          onChange={this.handleChange}
          name={input.name}
          autoComplete="off"
          showMonthDropdown
          showYearDropdown
          {...pickerProps}
          placeholderText={placeholder}
          isClearable={clearable}
          disabled={disabled}
        />
      </RenderFormField>
    );
  }

  public handleChange = (date: Date | null) =>
    this.props.input.onChange(date && moment(date).format('YYYY-MM-DD'));
}

export default RenderDateField;
